import React from 'react'
import "./ContactMap.css"

const ContactMap = () => {
  return (
    <>
      <div>
        <div className='Container'>
        
       <div className='contact-map'>
       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3491.3959298909067!2d77.6579069760396!3d28.94597886977681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c65ccc18f6d0b%3A0xa72492895eed021a!2sTsd%20Enterprises%20(Meerut%20)!5e0!3m2!1sen!2sin!4v1719221807768!5m2!1sen!2sin"
       width="600" height="100"  allowfullscreen loading="lazy" title="map" referrerPolicy=""></iframe>
       </div>

        </div>
      </div>
    </>
  )
}

export default ContactMap;