import React from "react";
import Slider from "react-slick";
import "./Benefits.css";

const Benefits = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="benefit-text Container">
        <img
          src="https://ik.imagekit.io/xjjzkwxra/TSD/brick.png?updatedAt=1719056834560"
          className="brick-icon"
          loading="lazy"
          width="300px"
          height="300px"
        />
        <div>
          <h3>Benefits of Brick</h3>
        </div>
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          <div className="benefit-slide">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/TSD/image5.webp?updatedAt=1719293668294"
              loading="lazy"
              width="300px"
              height="300px"
            />
            <h3>Aesthetics</h3>
          </div>
          <div className="benefit-slide">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/TSD/image2.webp?updatedAt=1719293667935"
              loading="lazy"
              width="300px"
              height="300px"
            />
            <h3>Lowest Maintenance</h3>
          </div>
          <div className="benefit-slide">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/TSD/image6.webp?updatedAt=1719293668396"
              loading="lazy"
              width="300px"
              height="300px"
            />
            <h3>Financial Value</h3>
          </div>
          <div className="benefit-slide">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/TSD/image3%20.webp?updatedAt=1719293668327"
              loading="lazy"
              width="300px"
              height="300px"
            />
            <h3>Energy Efficiency</h3>
          </div>
          <div className="benefit-slide">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/TSD/image4%20.webp?updatedAt=1719293668266"
              loading="lazy"
              width="300px"
              height="300px"
            />
            <h3>Sustainability</h3>
          </div>
          <div className="benefit-slide">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/TSD/image1.webp?updatedAt=1719293668361"
              loading="lazy"
              width="300px"
              height="300px"
            />
            <h3>Durability</h3>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default Benefits;
