import React, { Suspense, useState } from "react";
import { lazy } from "react";
import "./Header.css";
import "./About.css";
import "./Banner.css";
import "./Service.css";
import "./Footer.css";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Element } from "react-scroll";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faBookmark, faCubes } from "@fortawesome/free-solid-svg-icons";
import { faCodepen } from "@fortawesome/free-brands-svg-icons";
import Benefits from "./Benefits";
import ContactMap from "./ContactMap";

const Gallery = lazy(() => import('./Gallery'));

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const showInMapClicked = () => {
    window.open("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3491.3959298909067!2d77.6579069760396!3d28.94597886977681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c65ccc18f6d0b%3A0xa72492895eed021a!2sTsd%20Enterprises%20(Meerut%20)!5e0!3m2!1sen!2sin!4v1719221807768!5m2!1sen!2sin"
    +28.622152968522244+","+77.38567508240752 );
  };

  return (
    <>
      <Helmet>
        <title> TSD Enterprises</title>
      </Helmet>
      <div className="nav-main">
        <div className="Container main-nav">
          <Link to="/home">
            <img
              src="https://ik.imagekit.io/xjjzkwxra/TSD/logo.png?updatedAt=1719234710170"
              loading="lazy"
              className="nav-logo"
              alt="logo"
              width="300px"
              height="300px"
            />
          </Link>
          <ul
            className={isOpen ? "nav-links-mobile" : "nav-links"}
            onClick={() => setIsOpen(false)}
          >
            <li className="nav-li">
              <Link
                to="section1"
                smooth={true}
                duration={-200}
                active
                className="nav-home"
              >
                Home
              </Link>
            </li>

            <li className="nav-li">
              <Link
                to="section2"
                smooth={true}
                duration={-200}
                className="nav-home"
              >
                About Us
              </Link>
            </li>
            <li className="dropdowns nav__menu-item nav-li">
              <Link
                to="section3"
                smooth={true}
                duration={-200}
                className="nav-home"
              >
                Product
              </Link>
            </li>

            <li className="nav-li">
              <Link
                to="section5"
                smooth={true}
                duration={-200}
                className="nav-home"
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <button
            className="mobile-menu-icon"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <FontAwesomeIcon icon={faBars} className="nav-icon" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="nav-icon" />
            )}
          </button>
        </div>
      </div>
      <div className="whatsapp-chat">
        <a
          href="https://wa.me/7454085909"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://ik.imagekit.io/xjjzkwxra/ROI-MANTRA/whatsapp-873316_640.png?updatedAt=1715775097912"
            loading="lazy"
            alt="whatsapp"
            width="300px"
                  height="300px"
          />
        </a>
      </div>
      <div className="phone-chat">
        <a
          href="tel:7454085909"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://ik.imagekit.io/xjjzkwxra/TSD/call.png?updatedAt=1719297877349"
            loading="lazy"
            alt="phone"
            width="300px"
                  height="300px"
          />
        </a>
      </div>

      <Element name="section1">
        <div className="banner">
          <div className="banner-title Container flex">
            <div className="banner-text">
              <h1 className="banner-text-h1">"Transforming Spaces with Timeless Bricks: Where Elegance Meets Endurance.
                 Crafted with Precision, Built for Sustainability."
              </h1>
            </div>
          </div>
        </div>
      </Element>

      <Benefits />

      <Element name="section2">
        <section className="about-main">
          <div className=" about-box">
            <div className="about-img">
              <img
                src="https://ik.imagekit.io/xjjzkwxra/TSD/brcik%20(1).webp?updatedAt=1719293268894"
                width="300px"
                height="300px"
                 loading="lazy"
              />
            </div>
            <div className="about-content">
              <div className="about-content-text">
                <div className="about-content-text-icon">
                  <FontAwesomeIcon icon={faCubes} />
                </div>
                <div>
                  <h3>BUILD THE DREAMS</h3>
                  <p>
                    Whether you're constructing a cozy family home, a robust
                    commercial building, or an artistic architectural marvel, we
                    have the expertise and quality materials to bring your dream
                    to life.
                  </p>
                </div>
              </div>

              <div className="about-content-text">
                <div className="about-content-text-icon">
                  <FontAwesomeIcon icon={faCodepen} />
                </div>
                <div>
                  <h3>GET THE BEST SERVICES</h3>
                  <p>
                    Our commitment to quality, innovation, and customer
                    satisfaction ensures that you receive the best possible
                    service for all your construction needs.
                  </p>
                </div>
              </div>

              <div className="about-content-text">
                <div className="about-content-text-icon">
                  <FontAwesomeIcon icon={faBookmark} />
                </div>
                <div>
                  <h3>ALWAYS LEADING</h3>
                  <p>
                    Our commitment to innovation, quality, and customer
                    satisfaction has established us as a leader in the brick
                    industry. With a forward-thinking approach and a dedication
                    to excellence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>

      <Element name="section3">
        <div className="service-main">
          <div class=" Container ">
            <div className="service-header">
              <h1>Our Product</h1>
              <h3> Fly ash Concrete Block</h3>
            </div>
            <section id="timeline">
              <div class="tl-item">
                <div
                  class="tl-bg"
                  style={{
                    backgroundImage:
                      "url('https://ik.imagekit.io/xjjzkwxra/TSD/row.webp?updatedAt=1719293992837')",
                  }}
                   loading="lazy"
                   width="300px"
                  height="300px"
                ></div>

                <div class="tl-year">
                  <p class="f2 heading--sanSerif">Row Materials</p>
                </div>

                <div class="tl-content">
                  <h1>Fly ash, Aggregate, Coarse sand, Cement and Mixture</h1>
                  <p>
                    Fly ash, Aggregate, Coarse sand, Cement and Mixture Fly ash
                    concrete blocks, produced using fly ash—a byproduct of coal
                    combustion—cement, aggregates, water, and various additives, are
                    increasingly popular due to their enhanced strength,
                    durability, and sustainability. These blocks are utilized in
                    numerous construction applications, ranging from residential
                    and commercial buildings to industrial structures and
                    educational institutions.
                  </p>
                </div>
              </div>

              <div class="tl-item">
                <div
                  class="tl-bg"
                  style={{
                    backgroundImage:
                      "url('https://ik.imagekit.io/xjjzkwxra/TSD/uses.webp?updatedAt=1719293992725')",
                  }}
                   loading="lazy"
                   width="300px"
                  height="300px"
                ></div>

                <div class="tl-year">
                  <p class="f2 heading--sanSerif">Place of use</p>
                </div>

                <div class="tl-content">
                  <h1 class="f3 text--accent ttu">
                    Internal, Outer and Boundary walls
                  </h1>
                  <p>
                    In infrastructure projects, fly ash concrete blocks are
                    favored for road construction, bridge supports, and public
                    amenities like sidewalks and community centers. Their use in
                    pavements, walkways, and garden walls showcases their
                    versatility in landscaping and outdoor applications,
                    offering both aesthetic appeal and functional benefits. The
                    high fire resistance of these blocks enhances building
                    security, while their strength and resistance to pests and
                    decay ensure structural integrity and longevity.
                  </p>
                </div>
              </div>

              <div class="tl-item">
                <div
                  class="tl-bg"
                  style={{
                    backgroundImage:
                      "url('https://ik.imagekit.io/xjjzkwxra/TSD/sec.webp?updatedAt=1719293992766')",
                  }}
                   loading="lazy"
                   width="300px"
                  height="300px"
                ></div>

                <div class="tl-year">
                  <p class="f2 heading--sanSerif">Security</p>
                </div>

                <div class="tl-content">
                  <h1 class="f3 text--accent ttu">
                    Its concrete, not easy to cut.
                  </h1>
                  <p>
                    Fly ash concrete blocks also contribute to energy efficiency
                    through superior thermal insulation, which aids in
                    temperature control and reduces energy consumption. The
                    acoustic insulation properties of these blocks provide
                    privacy and soundproofing, essential for residential and
                    commercial spaces. Additionally, these blocks are less
                    susceptible to pests and rot, further enhancing building
                    security and maintenance.
                  </p>
                </div>
              </div>

              <div class="tl-item">
                <div
                  class="tl-bg"
                  style={{
                    backgroundImage:
                      "url('https://ik.imagekit.io/xjjzkwxra/TSD/fur.webp?updatedAt=1719293992762')",
                  }}
                   loading="lazy"
                   width="300px"
                  height="300px"
                ></div>

                <div class="tl-year">
                  <p class="f2 heading--sanSerif">Equipments and Furniture's</p>
                </div>

                <div class="tl-content">
                  <h1 class="f3 text--accent ttu">
                    User friendly and compatible to traditional methods.
                  </h1>
                  <p>
                    In the manufacturing and construction process, various
                    equipment such as concrete mixers, molding machines, curing
                    chambers, and cutting tools are used to produce and handle
                    fly ash concrete blocks efficiently. These blocks are also
                    incorporated into outdoor furniture, including benches and
                    tables, as well as modular office furniture and storage
                    solutions, demonstrating their adaptability and durability
                    in both structural and decorative applications.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Element>
      <Element name="section4">
      <Suspense fallback={<p>.</p>}>
              <Gallery/>
      </Suspense>
        
      </Element>
      <ContactMap/>
      <Element name="section5">
        <div className="footer">
          <div className="Container">
            <div class="subscribe">
              <div class="subscribe__img">
                <img src="https://ik.imagekit.io/xjjzkwxra/TSD/logo.png?updatedAt=1719234710170"  loading="lazy" width="300px"
                  height="300px"/>
              </div>

              <p  onClick={showInMapClicked}>
                Reg Office :  444 Gram Sundra Putha , Near Indian oils depo, Rithani peer Delhi Road,
                 Meerut - 250103, Uttar pradesh
              </p>
              <p class="subscribe__copy">GSTIN: 09AAHFT2440G1Z8</p>
              <Link
                to="javascript:void(0)"
                onClick={() =>
                  (window.location = "mailto:tsd.meerut1@gmail.com")
                }
              >
                <p>Email: tsd.meerut1@gmail.com</p>
              </Link>
              <Link to="tel:7454085909">
                <p>Contact: +91  9711502424, 7454085909 </p>
              </Link>
              <div class="notice">
                <span class="notice__copy">
                  Copyright @2024 &nbsp;&nbsp; TSD Enterprises
                </span>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};

export default Header;
